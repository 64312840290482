import React from "react";

const SendEmailButton = () => {
  const sendTestEmail = () => {
    // Replace with your actual server URL
    const serverUrl = "http://localhost:3001/send-email";

    fetch(serverUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: "r.eickelbaum@solutiondynamic.de",
        subject: "Test Email",
        text: "This is a test email from my React app!",
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data.message, data.response))
      .catch((error) => console.error("Error:", error));
  };

  return <button onClick={sendTestEmail}>Send Test Email</button>;
};

export default SendEmailButton;
