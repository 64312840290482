import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#333",
        padding: "10px",
        color: "#fff",
      }}
    >
      Footer Content
    </footer>
  );
};

export default Footer;
