import React from "react";
import { Typography, Box, AppBar, Container, Toolbar } from "@mui/material";
import theme from "../theme";
import SendEmailButton from "./ui/SendEmailButton";
const Navbar = () => {
  const colors = theme.light.colors;
  return (
    <AppBar sx={{ bgcolor: colors.lightGreen }} position="static">
      <Container maxWidth="x1">
        <Toolbar sx={{ bgcolor: colors.red }}>
          <SendEmailButton />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
