import { createTheme } from "@mui/material";
const theme = createTheme({
  light: {
    colors: {
      lightGreen: "#118537",
      darkGreen: "#295425",
      red: "#a11e1f",
      yellow: "#d8a81c",
    },
    spacing: {
      small: "8px",
      medium: "16px",
      large: "24px",
    },
    typography: {
      fontFamily: "Roboto, serif",
    },
  },
  dark: {
    colors: {
      lightGreen: "#118537",
      darkGreen: "#295425",
      red: "#a11e1f",
      yellow: "#d8a81c",
    },
    spacing: {
      small: "8px",
      medium: "16px",
      large: "24px",
    },
    typography: {
      fontFamily: "Roboto, serif",
    },
  },
});

export default theme;
