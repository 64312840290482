// PageLayout.js
import React from "react";
import Footer from "./widgets/Footer";
import Navbar from "./widgets/Navbar";
import { isMobile } from "./utils";
const AppShell = ({ children }) => {
  const mobile = isMobile();
  return (
    <div>
      <div>
        <Navbar />
        {/* Content box */}
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default AppShell;
