// App.js or index.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppShell from "./components/AppShell";
import Home from "./components/pages/Home";
import Typography from "@mui/material/Typography";
import { isMobile } from "./components/utils";
import { ThemeProvider } from "@mui/material";
import theme from "./components/theme";
const App = () => {
  const Mobile = isMobile();
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <AppShell>
                <Typography variant="h2" gutterBottom>
                  Welcome to React
                </Typography>
                <Home />
              </AppShell>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
