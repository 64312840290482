import React from "react";
import { Button } from "@mui/material";

const Home = () => {
  return (
    <div>
      <Button variant="contained">Hello</Button>
    </div>
  );
};

export default Home;
